<template>
  <v-container class="py-12">
    <v-row justify="center">
      <v-col v-for="(item, i) in players" :key="i" cols="6" sm="4" md="2">
        <a
          :href="require(`@/assets/players/download/${item.value}.png`)"
          download
        >
          <v-img
            :src="require(`@/assets/players/${item.value}.jpg`)"
            class="mb-4 zoom"
          >
            <div class="image-grad fill-height" />
          </v-img>
        </a>

        <div class="d-flex flex-column justify-center align-center text-center">
          <div class="text-body-1 text-md-h5 primary--text">
            <span class="rubik">
              {{ item.nickname }}
            </span>
          </div>

          <div class="text-body-2 text-md-h6 mb-1">
            <span class="rubik">
              {{ item.name }}
            </span>
          </div>

          <div class="text-caption text-md-body-1 font-weight-light">
            <span class="rubik spacing">
              {{ item.function }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      players: [
        {
          value: "f0mf3r4",
          nickname: "AFJ F0mf3r4",
          name: "Tiago Miranda",
          function: "IGL",
        },
        {
          value: "ztripa",
          nickname: "AJF Ztripa",
          name: "Lucas Mendonça",
          function: "RIFLER",
        },
        {
          value: "iguin",
          nickname: "AJF Iguin",
          name: "Igor Ramos",
          function: "Support",
        },
        {
          value: "heavely",
          nickname: "AJF Heavely",
          name: "Pedro Nogueira",
          function: "Lurker",
        },
        {
          value: "diabaz",
          nickname: "AJF Diabaz",
          name: "Matheus Lins",
          function: "Awper",
        },
        {
          value: "elbode",
          nickname: "AJF El Bode",
          name: "Carneiro Neto",
          function: "Manager",
        },
        {
          value: "kingui",
          nickname: "AJF KinGui",
          name: "Guilherme Balancieri",
          function: "COACH",
        },
      ],
    };
  },
};
</script>

<style>
.spacing {
  letter-spacing: 8px;
}

.image-grad {
  background: rgb(255, 246, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 246, 0, 0) 75%,
    rgba(255, 246, 0, 0.15) 100%
  );
}
</style>